import React, { useRef, useState, useEffect, useCallback } from "react"
import {isMobile} from 'react-device-detect';
import mapboxgl from '!mapbox-gl';
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import LatLon from "mt-latlon";
import washSites from '../location_data/location-data'
import '../vendor/mapbox/mapbox-gl.scss'
import '../vendor/mapbox/mapbox-gl-geocoder.scss'
import './Map.scss'

const WashSiteMap = ({ mapContent, generalLng, generalLat, countryName }) => {

  // Map Box API token
  // mapboxgl.accessToken = 'pk.eyJ1IjoiaW1vcHBjIiwiYSI6ImNsNGgyenQyNDAwM3kzaXBqazlqOThpaTcifQ.hEwMGb-GOM3Ecmfq-BLdpQ';
  mapboxgl.accessToken = 'pk.eyJ1IjoiaW1vY2Fyd2FzaCIsImEiOiJjbHJ5dXM5anMxcDFoMmtteHplb2k0cGV3In0.SdxBDD_Yh7QB0g5o1_aB7w';
  // mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  // Setting state
  const [userLocation, setUserLocation] = useState({
    longitude: generalLng,
    latitude: generalLat
  })
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [closestWashesList, setClosestWashesList] = useState({});
  const [nearestVisible, setnearestVisible] = useState(false);

  // Make data set of wash sites co-ordinates smaller by filtering users country
  const filteredWashSites = washSites.filter(localWashSites => localWashSites.Country === countryName)

  // Initialize map when component mounts
  useEffect(() => {

    // Invoke mapbox map
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [21.08726, 46.68406],
      zoom: 12
    });

    // Add markers to map using location wash site JSON coordinates
    filteredWashSites.map((washSite) => {
      const el = document.createElement('div');
      el.className = 'marker';
      const washSiteCoords = [parseFloat(washSite.Longitude), parseFloat(washSite.Latitude)]
      // console.log(washSite.Name + washSiteCoords[0], washSiteCoords[1])
      return new mapboxgl.Marker(el).setLngLat([washSiteCoords[0], washSiteCoords[1]]).addTo(map);
    })

    // Add an extra pin at the default coordinates
    const defaultEl = document.createElement('div');
    defaultEl.className = 'marker'; // Use the same class as other markers for a consistent appearance
    new mapboxgl.Marker(defaultEl).setLngLat([21.08726, 46.68406]).addTo(map);

    // const mapInputEl = document.getElementById('mapBoxInput');

    // // Add the control to the map.
    // map.addControl(
    // new MapboxGeocoder({
    //   accessToken: mapboxgl.accessToken,
    //   mapboxgl: mapboxgl,
    //   addTo: mapInputEl
    // }));

    // Add the control to the map.
    let geocoder;
    if(mapContent.languageCode === "EN") {
      geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        // Set search results to be user country
        countries: countryName,
        placeholder: "Enter a location, address or postcode"
      });
    }
    if(mapContent.languageCode === "PT") {
      geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        // Set search results to be user country
        countries: countryName,
        placeholder: "Introduza uma localização, morada ou código postal"
      });
    }
    if(mapContent.languageCode === "ES") {
      geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        // Set search results to be user country
        countries: countryName,
        placeholder: "Introduce una ubicación, dirección o código postal"
      });
    }
    if(mapContent.languageCode === "LB") {
      geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        // Set search results to be user country
        countries: countryName,
        placeholder: "Gitt Äre Wunnuert, Är Adress oder Är Postleitzuel an"
      });
    }
    if(mapContent.languageCode === "HU") {
      geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        // Set search results to be user country
        countries: countryName,
        placeholder: "Adjon meg egy helyet, címet vagy irányítószámot"
      });
    }
    if(mapContent.languageCode === "NL") {
      geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        // Set search results to be user country
        countries: countryName,
        placeholder: "Voer een locatie, adres of postcode in"
      });
    }
    if(mapContent.languageCode === "PL") {
      geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        // Set search results to be user country
        countries: countryName,
        placeholder: "Wprowadź lokalizację, adres lub kod pocztowy"
      });
    }
    if(mapContent.languageCode === "DE") {
      geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        // Set search results to be user country
        countries: countryName,
        placeholder: "Geben Sie einen Ort, eine Adresse oder eine Postleitzahl ein"
      });
    }
    if(mapContent.languageCode === "FR") {
      geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        // Set search results to be user country
        countries: countryName,
        placeholder: "Entrez un emplacement, une adresse ou un code postal"
      });
    }
   
    document.getElementById('geocoder').appendChild(geocoder.onAdd(map));

    // Set an event listener that fires on geocoder input
    geocoder.on('result', (e) => {
      console.log(e);
      showNearestCarWashes(e.result.center[0], e.result.center[1])
    });


    // Set Map state
    setMap(map);

    // Clean up on unmount
    return () => map.remove();

  }, []);

  const analyticsEvent = (eventWebEventName, eventWebEventData, eventConversionName, eventConversionData) => {
    if (typeof window.gtag !== 'undefined') {
      if(eventWebEventName && eventWebEventData) {
        window.gtag(eventWebEventName, "click", {send_to: "G-R17BKG6NML", ...eventWebEventData})
      } else if(eventConversionName && eventConversionData) {
        window.gtag(eventConversionName, "click", {send_to: "G-R17BKG6NML", ...eventConversionData})
      } else if(eventWebEventName) {
        window.gtag(eventWebEventName, "click", {send_to: "G-R17BKG6NML"})
      } else if(eventConversionName) {
        window.gtag(eventConversionName, "click", {send_to: "G-R17BKG6NML"})
      }
    }
  }

  // Get specific user location - with permission 
  const geoLocateUser = () => {
    
    analyticsEvent("map_search")
    
    const options = {
      enableHighAccuracy: true
    }
    const showError = (error) => {
      switch(error.code) {
        case error.PERMISSION_DENIED:
          console.log("User denied the request for Geolocation.")
          break;
        case error.POSITION_UNAVAILABLE:
          console.log("Location information is unavailable.")
          break;
        case error.TIMEOUT:
          console.log("The request to get user location timed out.")
          break;
        case error.UNKNOWN_ERROR:
          console.log("An unknown error occurred.")
          break;
        default:
          break;
      }
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError, options)
    } else {
      console.log("GeoLocation not supported")
    }
  }

  // Show user position (LNG, LAT) and callback to move Mapbox center
  const showPosition = (position) => {
    setUserLocation({
      longitude: position.coords.longitude,
      latitude: position.coords.latitude
    }, changeMapPosition(position.coords.longitude, position.coords.latitude))
  }

  // Move Mapbox center
  const changeMapPosition = (longitude, latitude) => {
    map.flyTo({
      center: [longitude, latitude]
    });

    const el = document.createElement('div');

    el.className = 'marker-user';
    // console.log(washSite.Name + washSiteCoords[0], washSiteCoords[1])
    new mapboxgl.Marker(el).setLngLat([longitude, latitude]).setPopup(new mapboxgl.Popup().setHTML("<h1>Hello World!</h1>")).addTo(map);

    showNearestCarWashes(longitude, latitude);
  }

  const highlightWash = (longitude, latitude) => {
    map.flyTo({
      center: [longitude, latitude]
    });
}

  // Compare users location against localised car wash site list and compile into an array
  const showNearestCarWashes = (longitude, latitude) => {
    setUserLocation({
      longitude: longitude,
      latitude: latitude
    })
    const userLocation = new LatLon(latitude, longitude);
    const closestWashes = []
    filteredWashSites.map((washSite) => {
      const washLocation = new LatLon(washSite.Latitude, washSite.Longitude);
      return closestWashes.push({Name: washSite.Name, Distance: userLocation.distanceTo(washLocation), Latitude: washSite.Latitude, Longitude: washSite.Longitude, Id: washSite.SiteNumber, OpeningHours: washSite.OpeningHours, Closed: washSite.Closed, Services: washSite.Services})
    })

    // Sort array into distance order
    closestWashes.sort((a, b) => a.Distance - b.Distance)
    
    // Show 5 closest car washes to users location
    const fiveClosestWashes = closestWashes.slice(0, 5)

    // Set state
    setClosestWashesList(fiveClosestWashes)

    setnearestVisible(true)
  }

  const siteDetails = useCallback(washSite => {
    const site = document.getElementById(`washsite-${washSite}`)
    site.classList.add('open')
  }, []);

  const closeDetails = useCallback(washSite => {
    const site = document.getElementById(`washsite-${washSite}`)
    site.classList.remove('open')
  }, [])

  if(mapContent) {
    return (
      <section id="mapElement" className="map wash-site-map">
        <div className="map-wrap">
          <div ref={mapContainerRef} className='map-container' />
        </div>
        <div className="map-content">
          <div className="map-content-inputs">
            <h3>IMO Békéscsaba</h3>
            <p>Szarvasi út 16-18<br/>5600 Hungary</p>
            <div style={{ display: 'none' }}>
            <h4>{mapContent.findYourNearestTitle}</h4>
            <p>{mapContent.findYourNearestSubtitle}</p>
            <div id="geocoder"></div>
              <a className="map-geolocate" onClick={() => geoLocateUser()} target="_blank" rel="noreferrer">
                <span>
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 297 297" xmlSpace="preserve">
                    <path fill="#ffffff" d="M148.5,0C66.653,0,0.067,66.616,0.067,148.499C0.067,230.383,66.653,297,148.5,297s148.433-66.617,148.433-148.501
                      C296.933,66.616,230.347,0,148.5,0z M158.597,276.411v-61.274c0-5.575-4.521-10.097-10.097-10.097s-10.097,4.521-10.097,10.097
                      v61.274c-62.68-4.908-112.845-55.102-117.747-117.814h61.207c5.575,0,10.097-4.521,10.097-10.097s-4.522-10.097-10.097-10.097
                      H20.656C25.558,75.69,75.723,25.497,138.403,20.589v61.274c0,5.575,4.521,10.097,10.097,10.097s10.097-4.521,10.097-10.097V20.589
                      c62.681,4.908,112.846,55.102,117.747,117.814h-61.207c-5.575,0-10.097,4.521-10.097,10.097s4.521,10.097,10.097,10.097h61.207
                      C271.441,221.31,221.276,271.503,158.597,276.411z"/>
                  </svg>
                  {mapContent.useMyLocationText}
                </span>
              </a>
            </div>
          </div>
          {/* <p>User is located at {userLocation.latitude} Latitude and {userLocation.longitude} Longitude</p> */}

            <div className={`${nearestVisible ? "show-nearest visible" : "show-nearest"}`} style={{ display: 'none' }}>
              {closestWashesList.length > 0 &&
                <div>
                  <h3>{mapContent.yourNearestImoCarWashSites}</h3>
                  <ul className="closest-wash-list">
                    {closestWashesList.map((washSite) => {
                      return (
                        <li key={washSite.Id} id={`washsite-${washSite.Id}`}>
                          <div className="closest-wash-list-title">
                            {/* Converts Kilometers to miles */}
                            <div>
                              <h4><a onClick={() => highlightWash(washSite.Longitude, washSite.Latitude)}>{washSite.Name}</a></h4>
                              {(countryName === "GB")  &&
                                <span>{(washSite.Distance / 1.609).toFixed(1)} miles</span>
                              }
                              {(countryName === "AU")  &&
                                 <span>{washSite.Distance} kilometres</span>
                              }
                              {(countryName === "PT")  &&
                                <span>{washSite.Distance} quilómetros</span>
                              }
                              {(countryName === "ES")  &&
                                <span>{washSite.Distance} kilómetros</span>
                              }
                              {(countryName === "LU")  &&
                                <span>{washSite.Distance} kilometeren</span>
                              }
                              {(countryName === "HU")  &&
                                <span>{washSite.Distance} kilométer</span>
                              }
                              {(countryName === "BE" && mapContent.languageCode === "NL")  &&
                                <span>{washSite.Distance} kilometers</span>
                              }
                              {(countryName === "BE" && mapContent.languageCode === "FR")  &&
                                <span>{washSite.Distance} kilomètres</span>
                              }
                              {(countryName === "PL")  &&
                                <span>{washSite.Distance} kilometry</span>
                              }
                              {(countryName === "AT")  &&
                                <span>{washSite.Distance} kilometer</span>
                              }
                              {(countryName === "DE")  &&
                                <span>{washSite.Distance} kilometer</span>
                              }
                              {(countryName === "FR")  &&
                                <span>{washSite.Distance} kilomètres</span>
                              }
                            </div>
                            {(isMobile)  &&
                              <div>
                                <a className="closest-wash-list-directions" href={`https://maps.google.com/?saddr=${userLocation.latitude},${userLocation.longitude}&daddr=${washSite.Latitude},${washSite.Longitude}`} target="_blank" rel="noreferrer">
                                  {mapContent.getDirectionsText}
                                </a>
                                <a className="closest-wash-list-directions details" onClick={() => {siteDetails(washSite.Id)}}>
                                  {mapContent.moreDetailsText}
                                </a>
                              </div>
                            }
                            {(!isMobile)  &&
                              <div>
                                <a className="closest-wash-list-directions details" onClick={() => {siteDetails(washSite.Id)}}>
                                  {mapContent.moreDetailsText}
                                </a>
                                <a className="closest-wash-list-directions" href={`https://maps.google.com/?saddr=${userLocation.latitude},${userLocation.longitude}&daddr=${washSite.Latitude},${washSite.Longitude}`} target="_blank" rel="noreferrer">
                                  {mapContent.getDirectionsText}
                                </a>
                              </div>
                            }
                          </div>
                          <div className="closest-wash-list-details">
                            <a className="closest-wash-list-details-close" onClick={() => {closeDetails(washSite.Id)}} />
                            <div className="closest-wash-list-details-wrap">
                              {(mapContent.languageCode === "EN")  &&
                                <div className="closest-wash-list-details-opening-hours">
                                  <h5>Opening hours</h5>
                                  <ul>
                                      <li>Monday <span>{washSite.OpeningHours.Monday}</span></li>
                                      <li>Tuesday <span>{washSite.OpeningHours.Tuesday}</span></li>
                                      <li>Wednesday <span>{washSite.OpeningHours.Wednesday}</span></li>
                                      <li>Thursday <span>{washSite.OpeningHours.Thursday}</span></li>
                                      <li>Friday <span>{washSite.OpeningHours.Friday}</span></li>
                                      <li>Saturday <span>{washSite.OpeningHours.Saturday}</span></li>
                                      <li>Sunday <span>{washSite.OpeningHours.Sunday}</span></li>
                                  </ul>
                                </div>
                              }
                              {(mapContent.languageCode === "PT")  &&
                                <div className="closest-wash-list-details-opening-hours">
                                  <h5>Horário de funcionamento</h5>
                                  <ul>
                                      <li>Segunda-feira <span>{washSite.OpeningHours.Monday}</span></li>
                                      <li>Terça-feira <span>{washSite.OpeningHours.Tuesday}</span></li>
                                      <li>Quarta-feira <span>{washSite.OpeningHours.Wednesday}</span></li>
                                      <li>Quinta-feira <span>{washSite.OpeningHours.Thursday}</span></li>
                                      <li>Sexta-feira <span>{washSite.OpeningHours.Friday}</span></li>
                                      <li>Sábado <span>{washSite.OpeningHours.Saturday}</span></li>
                                      <li>Domingo <span>{washSite.OpeningHours.Sunday}</span></li>
                                  </ul>
                                </div>
                              }
                              {(mapContent.languageCode === "ES")  &&
                                <div className="closest-wash-list-details-opening-hours">
                                  <h5>Horario de apertura</h5>
                                  <ul>
                                      <li>Lunes <span>{washSite.OpeningHours.Monday}</span></li>
                                      <li>Martes <span>{washSite.OpeningHours.Tuesday}</span></li>
                                      <li>Miércoles <span>{washSite.OpeningHours.Wednesday}</span></li>
                                      <li>Jueves <span>{washSite.OpeningHours.Thursday}</span></li>
                                      <li>Viernes <span>{washSite.OpeningHours.Friday}</span></li>
                                      <li>Sábado <span>{washSite.OpeningHours.Saturday}</span></li>
                                      <li>Domingo <span>{washSite.OpeningHours.Sunday}</span></li>
                                  </ul>
                                </div>
                              }
                              {(mapContent.languageCode === "LB")  &&
                                <div className="closest-wash-list-details-opening-hours">
                                  <h5>Ëffnungszäiten</h5>
                                  <ul>
                                      <li>Méindes <span>{washSite.OpeningHours.Monday}</span></li>
                                      <li>Dënschdes <span>{washSite.OpeningHours.Tuesday}</span></li>
                                      <li>Mëttwochs <span>{washSite.OpeningHours.Wednesday}</span></li>
                                      <li>Donneschdes <span>{washSite.OpeningHours.Thursday}</span></li>
                                      <li>Freides <span>{washSite.OpeningHours.Friday}</span></li>
                                      <li>Samschdes <span>{washSite.OpeningHours.Saturday}</span></li>
                                      <li>Sonndes <span>{washSite.OpeningHours.Sunday}</span></li>
                                  </ul>
                                </div>
                              }
                              {(mapContent.languageCode === "HU")  &&
                                <div className="closest-wash-list-details-opening-hours">
                                  <h5>Nyitvatartási idő</h5>
                                  <ul>
                                      <li>Hétfő <span>{washSite.OpeningHours.Monday}</span></li>
                                      <li>Kedd <span>{washSite.OpeningHours.Tuesday}</span></li>
                                      <li>Szerda <span>{washSite.OpeningHours.Wednesday}</span></li>
                                      <li>Csütörtök <span>{washSite.OpeningHours.Thursday}</span></li>
                                      <li>Péntek <span>{washSite.OpeningHours.Friday}</span></li>
                                      <li>Szombat <span>{washSite.OpeningHours.Saturday}</span></li>
                                      <li>Vasárnap <span>{washSite.OpeningHours.Sunday}</span></li>
                                  </ul>
                                </div>
                              }
                              {(mapContent.languageCode === "NL")  &&
                                <div className="closest-wash-list-details-opening-hours">
                                  <h5>Openingsuren</h5>
                                  <ul>
                                      <li>Maandag <span>{washSite.OpeningHours.Monday}</span></li>
                                      <li>Dinsdag <span>{washSite.OpeningHours.Tuesday}</span></li>
                                      <li>Woensdag <span>{washSite.OpeningHours.Wednesday}</span></li>
                                      <li>Donderdag <span>{washSite.OpeningHours.Thursday}</span></li>
                                      <li>Vrijdag <span>{washSite.OpeningHours.Friday}</span></li>
                                      <li>Zaterdag <span>{washSite.OpeningHours.Saturday}</span></li>
                                      <li>Zondag <span>{washSite.OpeningHours.Sunday}</span></li>
                                  </ul>
                                </div>
                              }
                              {(mapContent.languageCode === "PL")  &&
                                <div className="closest-wash-list-details-opening-hours">
                                  <h5>Godziny otwarcia</h5>
                                  <ul>
                                      <li>Poniedziałek <span>{washSite.OpeningHours.Monday}</span></li>
                                      <li>Wtorek <span>{washSite.OpeningHours.Tuesday}</span></li>
                                      <li>Środa <span>{washSite.OpeningHours.Wednesday}</span></li>
                                      <li>Czwartek <span>{washSite.OpeningHours.Thursday}</span></li>
                                      <li>Piątek <span>{washSite.OpeningHours.Friday}</span></li>
                                      <li>Sobota <span>{washSite.OpeningHours.Saturday}</span></li>
                                      <li>Niedziela <span>{washSite.OpeningHours.Sunday}</span></li>
                                  </ul>
                                </div>
                              }
                              {(mapContent.languageCode === "DE")  &&
                                <div className="closest-wash-list-details-opening-hours">
                                  <h5>Öffnungszeiten</h5>
                                  <ul>
                                      <li>Montag <span>{washSite.OpeningHours.Monday}</span></li>
                                      <li>Dienstag <span>{washSite.OpeningHours.Tuesday}</span></li>
                                      <li>Mittwoch <span>{washSite.OpeningHours.Wednesday}</span></li>
                                      <li>Donnerstag <span>{washSite.OpeningHours.Thursday}</span></li>
                                      <li>Freitag <span>{washSite.OpeningHours.Friday}</span></li>
                                      <li>Samstag <span>{washSite.OpeningHours.Saturday}</span></li>
                                      <li>Sonntag <span>{washSite.OpeningHours.Sunday}</span></li>
                                  </ul>
                                </div>
                              }
                              {(mapContent.languageCode === "FR")  &&
                                <div className="closest-wash-list-details-opening-hours">
                                  <h5>Heures d'ouverture</h5>
                                  <ul>
                                      <li>Lundi <span>{washSite.OpeningHours.Monday}</span></li>
                                      <li>Mardi <span>{washSite.OpeningHours.Tuesday}</span></li>
                                      <li>Mercredi <span>{washSite.OpeningHours.Wednesday}</span></li>
                                      <li>Jeudi <span>{washSite.OpeningHours.Thursday}</span></li>
                                      <li>Vendredi <span>{washSite.OpeningHours.Friday}</span></li>
                                      <li>Samedi <span>{washSite.OpeningHours.Saturday}</span></li>
                                      <li>Dimanche <span>{washSite.OpeningHours.Sunday}</span></li>
                                  </ul>

                                </div>
                              }
                              <div className="closest-wash-list-details-services">
                              <h5>{mapContent.servicesText}</h5>
                                <ul>
                                  {washSite.Services.map((service, i) => {
                                    return <li key={i}>{service}</li>
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </li>
                      )
                    })}
                  </ul>
                </div>
              }
            </div>  
        </div>
      </section>
    )
  } else {
    return (
      <div>Loading</div>
    )
  }
}
export default WashSiteMap;
